import React, { useCallback, useState } from 'react';
import CategoryKeys from '../types/CategoryKeys';
import CategoryType from '../types/CategoryType';
import DataMapType from '../types/DataMapType';
import { API_HOST, defaultRequestHeaders } from './Checklist';

type Props = {
  identifier: string | null;
  data: DataMapType | null;
  setData: (data: DataMapType) => void;
};

export default function NewContent({
  identifier,
  data,
  setData,
}: Props): JSX.Element {
  // Handle new content systems
  const [newContent, setNewContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = useCallback(
    (event) => setNewContent(event.target.value),
    [],
  );

  // Updating a single item
  const createItem = useCallback(async () => {
    // console.log('createItem called with', identifier);
    if (identifier == null || data == null || newContent.trim().length === 0) {
      return;
    }
    setIsLoading(true);

    const response = await fetch(`${API_HOST}/api/data/${identifier}`, {
      ...defaultRequestHeaders,
      method: 'PUT',
      body: JSON.stringify({
        completed: false,
        content: newContent.trim(),
        categoryId: 'custom',
      }),
    });
    const responseData = await response.json();
    // console.log('responseData', responseData);
    const { item, success } = responseData;
    const isSuccessful = success === true;
    if (isSuccessful) {
      const { category: categoryId, ...newItem } = item;
      const currentCategory = data[categoryId as CategoryKeys];
      const newCategory: CategoryType = {
        sectionTitle: currentCategory.sectionTitle,
        items: [...currentCategory.items, newItem],
      };

      const newData: DataMapType = {
        ...data,
        [categoryId]: newCategory,
      };

      setData(newData);
      setNewContent('');
    }
    setIsLoading(false);
  }, [identifier, data, newContent, setData]);

  return (
    <div className="flex mb-2">
      <input
        className="shadow border rounded w-full py-2 px-3 text-grey-800 leading-tight focus:outline-none focus:shadow-outline"
        type="text"
        name="content"
        placeholder="Add to Your List"
        value={newContent}
        onChange={handleInputChange}
        disabled={isLoading}
      />
      <button
        className="bg-red-500 hover:bg-red-800 text-white font-bold ml-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        id="create"
        type="submit"
        onClick={createItem}
        disabled={isLoading || newContent.length === 0}
      >
        Add
      </button>
    </div>
  );
}
