import React, { useCallback, useState } from 'react';
import DataMapType from '../types/DataMapType';
import { useEffect } from 'react';
import Container from '../components/Container';
import { useNavigate, useParams } from '@reach/router';
import randomWords from 'random-words';
import CategoryKeys from '../types/CategoryKeys';
import ItemType from '../types/ItemType';
import CategoryType from '../types/CategoryType';
import NewContent from './NewContent';
import TOSGLogo from '../tosg-red.png';
import { useClipboard } from 'use-clipboard-copy';
import ItemRow from './ItemRow';

export const API_HOST = 'https://checklist.teslaowners.sg';
// const API_HOST = process.env.NODE_ENV === 'development' ? 'http://checklist-api.koa.workers.dev' : 'https://checklist.teslaowners.sg';
export const LOCALSTORAGE_KEY = 'identifier_210720';

export const defaultRequestHeaders: RequestInit = {
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

type Props = {
  path?: string;
};

function Checklist(_props: Props) {
  const [data, setData] = useState<DataMapType | null>(null);
  const { identifier: paramIdentifier } = useParams();
  const [identifier, setIdentifier] = useState<string | null>(paramIdentifier);
  const navigate = useNavigate();
  const identifierExists = identifier != null && identifier.trim()?.length > 0;
  const [isNewUser, setIsNewUser] = useState<boolean>(!identifierExists);
  const clipboard = useClipboard({
    copiedTimeout: 1000, // timeout duration in milliseconds
  });

  // Manage loading state for each checklist item
  const [loadingItemIds, setLoadingItemIds] = useState<Set<number>>(new Set());
  const deleteLoadingItemId = useCallback(
    (itemId: number) => {
      setLoadingItemIds((loadingItemIds) => {
        const duplicate = new Set(loadingItemIds);
        duplicate.delete(itemId);
        return duplicate;
      });
    },
    [setLoadingItemIds],
  );

  // Used for initial load / refresh
  const getData = useCallback(
    async (identifier: string) => {
      // console.log('getData called with', identifier);
      if (!identifierExists) {
        return;
      }
      const response = await fetch(
        `${API_HOST}/api/data/${identifier}`,
        defaultRequestHeaders,
      );
      const responseData = await response.json();
      // console.log(responseData);
      setData(responseData.data);

      return responseData.data;
    },
    [setData, identifierExists],
  );

  // Updating a single item
  const updateItem = useCallback(
    async (itemId: number, completed: boolean, content?: string) => {
      // console.log('updateItem called with', identifier, 'for item', itemId);
      setLoadingItemIds(new Set(loadingItemIds.add(itemId)));
      if (!identifierExists || itemId == null || data == null) {
        return;
      }

      const response = await fetch(
        `${API_HOST}/api/data/${identifier}/${itemId}`,
        {
          ...defaultRequestHeaders,
          method: 'POST',
          body: JSON.stringify(
            content == null ? { completed } : { completed, content },
          ),
        },
      );
      const responseData = await response.json();
      // console.log('responseData', responseData);
      const { item, success } = responseData;
      // Explicit check is done as success can be null
      const isSuccessful = success === true;
      if (isSuccessful) {
        const { category: categoryId, ...newItem } = item;
        const currentCategory = data[categoryId as CategoryKeys];
        const newCategory: CategoryType = {
          sectionTitle: currentCategory.sectionTitle,
          items: currentCategory.items.map((existingItem: ItemType) =>
            existingItem.i === item.i ? newItem : existingItem,
          ),
        };

        const newData: DataMapType = {
          ...data,
          [categoryId]: newCategory,
        };

        setData(newData);
      }
      deleteLoadingItemId(itemId);
      return isSuccessful;
    },
    [identifier, identifierExists, data, deleteLoadingItemId, loadingItemIds],
  );

  // Page Load
  useEffect(() => {
    getData(identifier as string);
  }, [getData, identifier]);

  // Handle identifiers
  if (!identifierExists) {
    let newIdentifier = localStorage.getItem(LOCALSTORAGE_KEY);
    if (newIdentifier != null) {
      // console.log('Retrieved identifier from localStorage:', newIdentifier);
      setIsNewUser(false);
    } else {
      newIdentifier = randomWords(3).join('-');
      localStorage.setItem(LOCALSTORAGE_KEY, newIdentifier);
      // console.log('New identifier created and saved:', newIdentifier);
      setIsNewUser(true);
    }
    navigate(newIdentifier);
    setIdentifier(newIdentifier);
  }

  return (
    <Container>
      <div className="flex flex-row mb-2">
        <div className="flex-col flex-grow">
          <div className="flex flex-row items-center flex-wrap">
            <h1 className="text-grey-800 text-lg font-bold mr-1">
              Model 3 Delivery Checklist
            </h1>
            <span className="text-grey-800 text-sm font-light">
              by <a href="https://teslaowners.sg">Tesla Owners Singapore</a>
            </span>
          </div>

          {identifierExists ? (
            <div className="text-grey-800 text-md font-light">
              ID:
              <strong
                className="font-normal ml-1 cursor-pointer text-blue-500"
                onClick={() => {
                  clipboard.copy(window.location.href);
                }}
              >
                {identifier ?? 'New User'}
              </strong>
              {clipboard.copied && ' - Link Copied to clipboard!'}
            </div>
          ) : (
            <h3 className="block text-grey-800 text-md font-light my-2">
              No identifier found, generating a new one...
            </h3>
          )}

          {isNewUser && (
            <div
              className="bg-blue-100 border border-blue-400 text-blue-700 px-3 py-2 mt-1 rounded relative"
              role="alert"
            >
              <strong className="font-bold mr-1">Welcome!</strong>
              <span className="text-grey-800">
                As its your first time here, you've been assigned a new{' '}
                <strong className="font-bold">unique URL</strong>:
                <strong
                  className="ml-1 cursor-pointer text-blue-500"
                  onClick={() => {
                    clipboard.copy(window.location.href);
                  }}
                >
                  {identifier ?? 'New User'}
                </strong>
                <br />
                Save the URL and you can come back to your own list anytime, and
                on any device!
              </span>
            </div>
          )}
        </div>
        <a href="https://teslaowners.sg/">
          <img
            src={TOSGLogo}
            className="object-scale-down h-20 sm:h-32 flex-shrink"
            alt="TOSG Logo"
          />
        </a>
      </div>
      {identifierExists && (
        <NewContent data={data} setData={setData} identifier={identifier} />
      )}
      {identifierExists &&
        data != null &&
        Object.entries(data).map(([categoryId, { sectionTitle, items }]) => (
          <div className="flex flex-col" key={categoryId}>
            <h3 className="block text-grey-800 text-md font-bold my-2">
              {sectionTitle}
            </h3>
            {items.map(({ i, c, d }) => (
              <ItemRow
                key={i}
                itemId={i}
                content={c}
                isCompleted={d}
                isItemLoading={loadingItemIds.has(i)}
                updateItem={updateItem}
              />
            ))}
          </div>
        ))}
    </Container>
  );
}

export default Checklist;
