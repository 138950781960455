import React from 'react';
import { Router } from '@reach/router';
import './App.css';
import Checklist from './components/Checklist';

function App() {
  return (
    <Router>
      <Checklist path="/" />
      <Checklist path="/:identifier" />
    </Router>
  );
}

export default App;
