import React, { ReactNode } from 'react';
import Footer from './Footer';

type Props = {
  children: ReactNode;
};

export default function Container({ children }: Props): JSX.Element {
  return (
    <div className="container mx-auto px-0 py-0 md:py-2 md:px-2 lg:py-8">
      <div className="bg-white shadow-md rounded px-3 md:px-4 lg:px-6 xl:px-8 pt-6 py-8 mb-4">
        {children}
      </div>
      <Footer />
    </div>
  );
}
