import React, { useMemo } from 'react';
import ItemType from '../types/ItemType';

import { ReactComponent as CheckIcon } from '../check.svg';
import { ReactComponent as RingsIcon } from '../rings.svg';

type Props = {
  itemId: ItemType['i'];
  isCompleted: ItemType['d'];
  content: ItemType['c'];
  isItemLoading: boolean;
  updateItem: (itemId: ItemType['i'], checked: boolean) => void;
};

const fullLinkOnlyRegex = /\[([^[]+)\]\((.*)\)/;

export default function ItemRow({
  itemId,
  isCompleted,
  content,
  isItemLoading,
  updateItem,
}: Props): JSX.Element {
  const textContent = useMemo(() => {
    const match = content.match(fullLinkOnlyRegex);
    if (match != null) {
      const [full, text, url] = match;
      const index = match.index as number;
      return (
        <span>
          {content.substr(0, index)}
          <a
            href={url}
            rel="noreferrer"
            className="text-blue-600 font-medium"
            target="_blank"
          >
            {text}
          </a>
          {content.substr(index + full.length)}
        </span>
      );
    }

    return <span>{content}</span>;
  }, [content]);

  return (
    <div className="border-t py-4 flex flex-row justify-start items-center">
      {isItemLoading ? (
        <div className="border-2 rounded-md border-blue-200 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 cursor-wait">
          <span className="fill-current w-7 h-7 text-blue-600 pointer-events-none">
            <RingsIcon />
          </span>
        </div>
      ) : isCompleted ? (
        <div
          className="border-2 rounded-md border-blue-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 cursor-pointer"
          onClick={() => updateItem(itemId, !isCompleted)}
        >
          <span className="fill-current w-4 h-3 text-blue-600 pointer-events-none">
            <CheckIcon />
          </span>
        </div>
      ) : (
        <div
          className="border-2 rounded-md border-red-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 cursor-pointer"
          onClick={() => updateItem(itemId, !isCompleted)}
        >
          &nbsp;
        </div>
      )}
      <span className={`flex-auto${isCompleted ? ' line-through' : ''}`}>
        {textContent}
      </span>
    </div>
  );
}
